import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

interface Props extends ReactMarkdown.ReactMarkdownProps {}

const JobDescriptionMarkdown: React.FC<Props> = props => {
  return (
    <ReactMarkdown
      escapeHtml
      className="mt-6"
      {...props}
      renderers={{
        heading: MarkdownHeading,
        listItem: MarkdownListItem,
        link: MarkdownLink,
        paragraph: MarkdownParagraph
      }}
    />
  );
};

// See https://github.com/rexxars/react-markdown

interface ParagraphProps {
  children: React.ReactChildren;
}

const MarkdownParagraph: React.FC<ParagraphProps> = ({ children }) => {
  return <p className="leading-loose mt-4 break-words text-gray-600">{children}</p>;
};

interface LinkProps {
  href: string;
  children: React.ReactChildren;
}

const MarkdownLink: React.FC<LinkProps> = ({ children, href }) => {
  if (href.startsWith("/")) {
    return (
      <Link
        to={href}
        className="underline ag-text-light-purple"
      >
        {children}
      </Link>
    );
  }
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="underline text-indigo-500 hover:text-indigo-700"
    >
      {children}
    </a>
  );
};

interface HeadingProps {
  level: number;
  children: React.ReactNode | React.ReactNode[];
}
const MarkdownHeading: React.FC<HeadingProps> = ({ level, children }) => {
  switch (level) {
    case 1:
      return <h1 className="text-3xl mt-6 mb-2">{children}</h1>;
    case 2:
      return <h2 className="text-2xl mt-4 mb-2">{children}</h2>;
    case 3:
      return <h3 className="text-xl my-2">{children}</h3>;
    default:
      return <h4 className="font-bold my-2">{children}</h4>;
  }
};

interface ListItemProps {
  checked: boolean | null;
  tight: boolean;
  ordered: boolean;
  index: number;
  children: React.ReactNode | React.ReactNode[];
}
const MarkdownListItem: React.FC<ListItemProps> = ({ children }) => {
  return <li className="list-disc list-outside ml-4 mt-2 text-gray-600">{children}</li>;
};

export default JobDescriptionMarkdown;
