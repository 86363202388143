// Inspired by https://github.com/jaredpalmer/formik/blob/e51f09a318cba216a1ba3932da0906202df0b979/examples/DebouncedAutoSave.js
import React, { useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import debounce from "lodash/debounce";

interface Props {
  debounceMs?: number;
}

/**
 * Automatically submit a Formik + Form instance whenever the values change.
 * Debounced so that submit isn't called excessively.
 */
const FormikAutoSubmit: React.FC<Props> = ({ debounceMs = 300 }) => {
  const formik = useFormikContext();
  const debouncedSubmit = useCallback(debounce(formik.submitForm, debounceMs), [
    debounceMs,
    formik.submitForm
  ]);

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return null;
};

export default FormikAutoSubmit;
