import React from "react";
import classNames from "classnames";

interface Props {
  variant: "error";
  title: string;
  children: React.ReactNode;
}

const Card: React.FC<Props> = ({ variant, title, children }) => {
  return (
    <div
      role={variant === "error" ? "alert" : undefined}
      className="max-w-lg mx-auto"
    >
      <div
        className={classNames("font-bold rounded-t px-4 py-2", {
          "bg-red-500 text-white": variant === "error"
        })}
      >
        {title}
      </div>
      <div
        className={classNames(
          "border border-t-0 rounded-b px-4 py-3 leading-relaxed",
          {
            "border-red-400 bg-red-100 text-red-700": variant === "error"
          }
        )}
      >
        { children }
      </div>
    </div>
  );
};

export default Card;
