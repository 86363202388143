import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Persist } from "formik-persist";

import ProgressHeader from "./ProgressHeader";
import Information from "./Information";
import Preview from "./Preview";
import Payment from "./Payment";
import Confirmation from "./Confirmation";
import schema from "./schema";
import { useTitle } from "../../hooks";

/*
N.B. See https://github.com/jaredpalmer/formik/issues/2021 for a good idea
for how to implement a multi-page formik
*/

const steps = [
  {
    name: "Info",
    component: Information,
  },
  {
    name: "Preview",
    component: Preview,
  },
  {
    name: "Purchase",
    component: Payment,
  },
  {
    name: "Celebrate",
    component: Confirmation,
  }
];
const stepNames = steps.map(s => s.name);

const NewListing: React.FC = () => {
  const [step, setStep] = useState(0);
  useTitle("Post a Job");

  const handleContinuePress = (errors: any) => {
    if (errors) return;
    setStep(Math.max(step + 1, steps.length - 1));
  }

  const handleStepChange = ({ stepIndex }: { stepIndex: number }) =>
    setStep(stepIndex);
  const CurrentStep = steps[step].component;

  return (
    <div className="flex-grow">
      <ProgressHeader
        title="Post a Job"
        stepNames={stepNames}
        currentStep={step}
        onStepChange={handleStepChange}
      />
      <div className="pt-6 px-2 md:px-0 ag-container mx-auto">
        <Formik
          initialValues={{
            submitterEmail: "",
            companyName: "",
            title: "",
            description: "",
            location: "",
            isRemoteAllowed: false,
            category: "",
            employmentType: "",
            seniority: "",
            salaryMin: "",
            salaryMax: "",
            apply: "",
          }}
          validationSchema={schema}
          onSubmit={console.info}
        >
          {(formikProps) => (
            <Form>
              <CurrentStep
                onContinue={() => handleContinuePress(formikProps.errors)}
                {...formikProps}
              />
              <Persist name="new-listing" />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default NewListing;
