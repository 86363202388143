import React from "react";

import Header from "../../components/Header";
import JobListings from "./JobListings";


const Home: React.FC = () => {

  return (
    <main className="flex-grow">
      <Header />
      <JobListings />
    </main>
  )
};

export default Home;
