import React from "react";
import { useLocation, Link } from "react-router-dom";

const NotFound: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <div className="flex-grow">
      <div className="ag-container mx-auto my-16 text-center">
        <h1 className="text-5xl font-bold">
          <span role="img" aria-label="Shrug">🤷‍♂️</span>
          <br />
          That Page Doesn't Exist
        </h1>
        <p className="mt-8 text-lg leading-relaxed text-gray-800">
          Sorry, but we don't have a page for {pathname} it has either expired
          or it never existed.
        </p>
        <p className="mt-4 text-lg leading-relaxed text-gray-800">
          Try heading to our{" "}
          <Link className="blue-link" to="/">
            home page
          </Link>{" "}
          to see the latest jobs.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
