import React from "react";
import classNames from "classnames";


interface Props {
  stepNames: string[];
  currentStep: number;
  title?: string;
  onStepChange: ({ stepName, stepIndex }: { stepName: string, stepIndex: number}) => void;
}

const ProgressHeader: React.FC<Props> = ({ title="New Listing", stepNames, currentStep, onStepChange }) => {
  const steps = stepNames.map((stepName, i) => (
    <button
      key={stepName}
      className={classNames("pb-4 border-b-4 block focus:outline-none flex-1", {
        "border-ag-light-purple": i === currentStep,
        "border-gray-500": i !== currentStep,
        "cursor-default": i > currentStep
      })}
      onClick={i <= currentStep ? () => onStepChange({ stepName, stepIndex: i }) : undefined}
    >
      <div className={classNames("uppercase tracking-wider font-semibold text-center", {
        "text-ag-light-purple": i === currentStep,
        "text-gray-500": i !== currentStep,
      })}
      >
        { stepName }
      </div>
    </button>
  ));

  return (
    <nav className="mt-16 ag-container mx-auto px-2 md:px-0">
      <h2 className="uppercase text-ag-dark-purple font-semibold tracking-wide text-sm mb-4">
        {title}
      </h2>
      <div className="mt-2 w-full md:w-1/2 flex items-center justify-around text-xs">
        { steps }
      </div>
    </nav>
  )
}

export default ProgressHeader;
