const OUTBOUND_QUERY_PARAMS = {
  utm_medium: "referral",
  utm_source: "agencycheddar.com",
  utm_campaign: "agency cheddar job listing",
  ref: "agencycheddar.com"
};

export function addOutboundParams(url: string) {
  const _url = new URL(url);
  Object.entries(OUTBOUND_QUERY_PARAMS).forEach(([key, val]) =>
    _url.searchParams.set(key, val)
  );
  return _url.toString();
}

export function truncate(s: string, maxLength: number, suffix = "...") {
  if (s.length >= (maxLength - suffix.length)) {
    return `${s.slice(0, maxLength - 1)}${suffix}`;
  }
  return s;
}