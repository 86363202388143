import React from "react";
import { Link } from "react-router-dom";


const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-gray-900 mt-16">
      <div className="ag-container mx-auto py-8 flex items-center">
        <p className="w-full text-sm text-gray-100 text-center">
          <Link className="underline" to="/terms-of-use">Terms of Use</Link> &middot;
          Privacy Policy &middot;
          Copyright &copy; {year}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
