import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { parseISO, formatDistanceToNow } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faMapMarkerAlt,
  faDollarSign,
  faUserTie,
  faGlobeAmericas,
  faLink,
  faTag,
  faSitemap
} from "@fortawesome/free-solid-svg-icons";
import { format as d3Format } from "d3-format";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Spring } from "react-spring/renderprops";

import JobDescriptionMarkdown from "../components/JobDescriptionMarkdown";
import { addOutboundParams } from "../utils";
import { GQLJobListing } from "../graphql";
import NotFound from "./NotFound";
import {
  COMPANY_SIZES,
  JOB_LISTING_SENIORITY,
  JOB_LISTING_EMPLOYMENT_TYPES,
  JOB_LISTING_CATEGORIES
} from "../constants";
import { useTitle } from "../hooks";

const JOB_LISTING = gql`
  query GQLJobListing($slug: String!) {
    jobListings(slug: $slug, first: 1) {
      edges {
        node {
          title
          description
          createdAt
          category
          location
          isRemoteAllowed
          salaryMin
          salaryMinCurrency
          salaryMax
          applyLink
          applyEmail
          employmentType
          seniority
          company {
            name
            logoThumbnailUrl
            size
            website
            linkedinCompanyUrl
          }
        }
      }
    }
  }
`;
const formatCurrency = d3Format("$,");

const JobListingDetail: React.FC = () => {
  const { slug } = useParams();
  const { data, loading, error } = useQuery<GQLJobListing>(JOB_LISTING, {
    variables: { slug }
  });
  const listing = useMemo(() => data?.jobListings?.edges[0]?.node, [data]);
  const [salaryMin, salaryMax] = useMemo(() => {
    if (!listing) return [null, null];

    if (!listing.salaryMin || !listing.salaryMax) return [null, null];

    return [
      formatCurrency(listing.salaryMin),
      formatCurrency(listing.salaryMax)
    ];
  }, [listing]);
  const websiteHostname = useMemo(() => {
    if (!listing?.company?.website) return null;

    const url = new URL(listing.company.website);
    return url.hostname;
  }, [listing]);
  const applyLink = useMemo(() => {
    if (!listing?.applyEmail && !listing?.applyLink) return null;
    return listing.applyLink
      ? addOutboundParams(listing.applyLink)
      : `mailto:${listing.applyEmail}`;
  }, [listing]);

  useTitle(
    listing ? `${listing.title} - ${listing.company.name}` : "Not Found"
  );

  if (loading) {
    return <div className="flex-grow"></div>;
  }

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  if (!listing) {
    return <NotFound />;
  }

  const mobileLogoSize = 100;
  return (
    <div className="flex-grow">
      <div className="ag-container mx-auto p-4 md:p-0 my-8 md:my-16 md:flex md:flex-row-reverse">
        {/* Mobile company info */}
        <div className="md:hidden flex mb-8 bg-white px-2 py-4 rounded border border-gray-300">
          {listing.company?.logoThumbnailUrl ? (
            <div>
              <img
                className="border border-gray-300"
                alt={listing.company.name}
                src={listing.company.logoThumbnailUrl}
                width={mobileLogoSize}
                height={mobileLogoSize}
                style={{ maxWidth: mobileLogoSize, maxHeight: mobileLogoSize }}
              />
            </div>
          ) : (
            <div
              className="rounded-full bg-gray-500"
              style={{ minWidth: mobileLogoSize, minHeight: mobileLogoSize }}
            />
          )}
          <div className="ml-4 flex-1 min-w-0 text-sm">
            {listing.company.website ? (
              <a
                className="text-ag-light-purple"
                href={addOutboundParams(listing.company.website)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <h2 className="text-ag-light-purple font-semibold">
                  {listing.company.name}
                  <FontAwesomeIcon icon={faLink} className="ml-2" />
                </h2>
              </a>
            ) : (
              <h2 className="text-ag-light-purple font-semibold">
                {listing.company.name}
              </h2>
            )}
            <p className="mt-1 text-gray-600">
              <FontAwesomeIcon icon={faBuilding} className="mr-2" />
              {listing.company && COMPANY_SIZES[listing.company.size]}
            </p>
            <ul className="mt-1 flex text-gray-600">
              {listing.company.linkedinCompanyUrl && (
                <li>
                  <a
                    href={addOutboundParams(
                      `${listing.company.linkedinCompanyUrl}people`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        {/* Desktop company info */}
        <div
          style={{ flexBasis: "250px" }}
          className="hidden md:block md:flex-shrink-0"
        >
          <div className="bg-white rounded shadow-lg p-4 flex flex-col items-center border border-gray-300">
            {listing.company?.logoThumbnailUrl && (
              <img
                alt={listing.company.name}
                src={listing.company.logoThumbnailUrl}
                width="150"
                height="150"
                style={{ maxWidth: "150px", maxHeight: "150px" }}
              />
            )}
            <h1 className="font-semibold my-8">{listing.company?.name}</h1>
            {listing.company?.website && websiteHostname && (
              <a
                href={addOutboundParams(listing.company?.website)}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-2 text-gray-700"
              >
                <FontAwesomeIcon icon={faLink} className="mr-2" />
                {websiteHostname}
              </a>
            )}
            <p className="mt-2 text-gray-700">
              <FontAwesomeIcon icon={faBuilding} className="mr-2" />
              {listing.company && COMPANY_SIZES[listing.company.size]}
            </p>
            {listing.company?.linkedinCompanyUrl && (
              <a
                href={addOutboundParams(
                  `${listing.company.linkedinCompanyUrl}people`
                )}
                target="_blank"
                rel="noopener noreferrer"
                className="text-ag-light-purple text-center my-2"
              >
                <FontAwesomeIcon icon={faLinkedin} className="mr-2" />
                See who you know on LinkedIn
              </a>
            )}
            {/* <a
              href="https://www.glassdoor.ca/Location/Sid-Lee-Toronto-Location-EI_IE383034.0,7_IL.8,15_IC2281069.htm"
              target="_blank"
              rel="noopener noreferrer"
              className="text-ag-light-purple text-center my-2"
            >
              Check out {listing.company.name} on Glassdoor
            </a> */}
            <a
              href={applyLink ?? "#"}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-ag-light-purple text-white rounded p-4 leading-none border my-8"
            >
              Apply for this position
            </a>
          </div>
        </div>
        <Spring
          from={{ transform: `translate(0, 50px)`, opacity: 0 }}
          to={{ transform: `translate(0, 0)`, opacity: 1 }}
        >
          {styles => (
            <div style={styles} className="md:flex-grow md:mr-6">
              <h1 className="text-3xl text-ag-light-purple">{listing.title}</h1>
              <div className="flex text-sm text-gray-600 mt-2 pb-4 border-b-2 border-ag-light-purple">
                {/* column 1 */}
                <div className="flex-1 mr-1">
                  <div>
                    <FontAwesomeIcon
                      icon={faClock}
                      className="text-ag-light-purple"
                      style={{ width: 14 }}
                    />
                    <span className="ml-2">
                      {formatDistanceToNow(parseISO(listing.createdAt), {
                        includeSeconds: false,
                        addSuffix: true
                      }).replace("about ", "")}
                    </span>
                  </div>
                  <div className="mt-1">
                    {listing.isRemoteAllowed ? (
                      <>
                        <FontAwesomeIcon
                          icon={faGlobeAmericas}
                          className="text-ag-light-purple"
                          style={{ width: 14 }}
                        />
                        <span className="ml-2">Remote position</span>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="text-ag-light-purple"
                          style={{ width: 14 }}
                        />
                        <span className="ml-2">{listing.location}</span>
                      </>
                    )}
                  </div>
                  <div className="mt-1">
                    <FontAwesomeIcon
                      icon={faTag}
                      className="text-ag-light-purple"
                      style={{ width: 14 }}
                    />
                    <span className="ml-2">
                      {JOB_LISTING_CATEGORIES[listing.category]}
                    </span>
                  </div>
                  {salaryMin && salaryMax && (
                    <div className="mt-1">
                      <FontAwesomeIcon
                        icon={faDollarSign}
                        className="text-ag-light-purple"
                        style={{ width: 14 }}
                      />
                      <span className="ml-2">
                        {salaryMin} - {salaryMax}
                      </span>
                    </div>
                  )}
                </div>
                {/* column 2 */}
                <div className="flex-1 ml-1">
                  <div>
                    <FontAwesomeIcon
                      icon={faUserTie}
                      className="text-ag-light-purple"
                      style={{ width: 14 }}
                    />
                    <span className="ml-2">
                      {JOB_LISTING_EMPLOYMENT_TYPES[listing.employmentType]}
                    </span>
                  </div>
                  <div className="mt-1">
                    <FontAwesomeIcon
                      icon={faSitemap}
                      className="text-ag-light-purple"
                      style={{ width: 14 }}
                    />
                    <span className="ml-2">
                      {JOB_LISTING_SENIORITY[listing.seniority]}
                    </span>
                  </div>
                </div>
              </div>
              <JobDescriptionMarkdown
                className="mt-8"
                source={listing.description}
              />
              <a
                href={applyLink ?? "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-ag-light-purple text-white rounded p-4 leading-none border mt-8"
              >
                Apply for this position
              </a>
            </div>
          )}
        </Spring>
      </div>
    </div>
  );
};

export default JobListingDetail;
