import React from "react";
import { parseISO, formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import classNames from "classnames";
import * as d3Format from "d3-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import { listingFields as ListingFields } from "../../graphql";
import * as c from "../../constants";
import { truncate } from "../../utils";

interface Props {
  listing: ListingFields;
}

const JobListing: React.FC<Props> = ({ listing }) => {
  const outerClasses =
    "mb-4 border border-gray-300 hover:shadow-lg hover:border-gray-600 rounded overflow-hidden bg-white block";

  const formatMoney = d3Format.format("$,");
  return (
    <Link className={outerClasses} to={`/listing/${listing.slug}`}>
      <div className="flex p-4 h-full">
        {listing.company.logoThumbnailUrl ? (
          <div>
            <img
              className="border border-gray-300"
              src={listing.company.logoThumbnailUrl}
              alt={listing.company.name}
              width="100"
            />
          </div>
        ) : (
          <div
            className="rounded-full bg-gray-500"
            style={{
              minWidth: 100,
              minHeight: 100,
              flexBasis: 100,
              width: 100,
              height: 100
            }}
          />
        )}
        <div className="ml-4 flex-1 min-w-0">
          <h2 className="text-lg font-bold truncate">
            {listing.title}
          </h2>
          <h3 className="text-sm text-ag-light-purple mb-1">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
            {listing.location}
          </h3>
          <h3 className="text-sm mb-1">
            <span className="font-semibold text-ag-light-purple">
              {listing.company?.name}
            </span>
            <span className="mx-2 text-gray-600">/</span>
            <span className="text-gray-600">
              {c.JOB_LISTING_CATEGORIES[listing.category]}
            </span>
          </h3>
          <div className="mt-3 flex">
            <p className="text-gray-600 text-sm">
              {c.JOB_LISTING_EMPLOYMENT_TYPES[listing.employmentType]}
            </p>
            {listing.salaryMin && listing.salaryMax && (
              <p className="text-green-500 text-sm ml-2">
                {formatMoney(listing.salaryMin)} -{" "}
                {formatMoney(listing.salaryMax)}
              </p>
            )}
          </div>
          <p className="text-gray-500 text-sm">
            <FontAwesomeIcon icon={faClock} size="1x" className="mr-1" />
            {formatDistanceToNow(parseISO(listing.createdAt), {
              includeSeconds: false,
              addSuffix: true
            }).replace("about ", "")}
          </p>
        </div>
      </div>
      {listing.activePromotion && (
        <div
          className="w-full h-4 bg-ag-yellow"
          title={
            listing.activePromotion ? `This is a promoted listing.` : undefined
          }
        />
      )}
    </Link>
  );
};

export default JobListing;
