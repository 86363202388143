export const COMPANY_SIZES = {
  "XS": "< 10 employees",
  "SM": "10-49 employees",
  "M": "50-249 employees",
  "LG": "250+ employees"
};

export const JOB_LISTING_CATEGORIES = {
  "AM": "Account Management",
  "AD": "Administration",
  "AN": "Analytics / Marketing Science",
  "CR": "Creative",
  "FI": "Finance",
  "HR": "Human Resources",
  "IA": "Information Architecture (User Interface and/or Experience Design)",
  "ME": "Media",
  "BD": "Business Development",
  "PD": "Production",
  "PM": "Program Management",
  "PR": "Public Relations",
  "SM": "Social Media",
  "ST": "Strategy",
  "TE": "Technology"
};

export const JOB_LISTING_EMPLOYMENT_TYPES = {
  "F": "Full Time",
  "P": "Part Time",
  "C": "Contract"
};

export const JOB_LISTING_SENIORITY = {
  "J": "Junior",
  "M": "Mid-level",
  "S": "Senior",
  "E": "Executive"
};