import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useQueryParams, StringParam, NumberParam } from "use-query-params";
import * as yup from "yup";
import { Formik, Form } from "formik";
import { NetworkStatus } from "apollo-client";
import Loader from "react-loader-spinner";

import FormikAutoSubmit from "../../components/FormikAutoSubmit";
import { GQLJobListings } from "../../graphql";
import JobListing from "./JobListing";
import DesktopFiltersSidebar from "./DesktopFiltersSidebar";
import { useTitle } from "../../hooks";
import * as constants from "../../constants";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import Field from "../../components/Field";

const JOB_LISTINGS = gql`
  fragment listingFields on JobListingNode {
    id
    title
    category
    employmentType
    slug
    location
    salaryMin
    salaryMax
    salaryMinCurrency
    company {
      name
      logoThumbnailUrl
      size
    }
    originallyPostedAt
    createdAt
    activePromotion {
      features
    }
  }

  query GQLJobListings(
    $first: Int!
    $keyword: String
    $category: String
    $employmentType: String
    $seniority: String
    $location: String
    $after: String
  ) {
    pinnedListings: jobListings(first: 10, pinnedOnly: true) {
      edges {
        node {
          ...listingFields
        }
      }
    }

    nonPinnedListings: jobListings(
      first: $first
      excludePinned: true
      category: $category
      employmentType: $employmentType
      seniority: $seniority
      description_Icontains: $keyword
      location_Icontains: $location
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...listingFields
        }
      }
    }
  }
`;

const DEFAULT_NUM_LISTINGS = 25;

const variablesSchema = yup.object({
  first: yup
    .number()
    .min(10)
    .max(100)
    .default(DEFAULT_NUM_LISTINGS)
    .required(),
  after: yup.string().notRequired(),
  keyword: yup.string().notRequired(),
  category: yup
    .string()
    .notRequired()
    .oneOf(
      Object.keys(constants.JOB_LISTING_CATEGORIES).map(c => c.toLowerCase())
    ),
  location: yup.string().notRequired(),
  employmentType: yup
    .string()
    .notRequired()
    .oneOf(
      Object.keys(constants.JOB_LISTING_EMPLOYMENT_TYPES).map(c =>
        c.toLowerCase()
      )
    ),
  seniority: yup
    .string()
    .notRequired()
    .oneOf(
      Object.keys(constants.JOB_LISTING_SENIORITY).map(c => c.toLowerCase())
    )
});

const alertSchema = yup.object({
  email: yup
    .string()
    .email("Must be a valid email address")
    .required("Required")
});

type Variables = yup.InferType<typeof variablesSchema>;

const JobListings: React.FC = () => {
  useTitle("", "Agency Cheddar");
  const [query, setQuery] = useQueryParams({
    first: NumberParam,
    after: StringParam,
    keyword: StringParam,
    location: StringParam,
    category: StringParam,
    employmentType: StringParam
  });
  const [mobileModalVisible, setMobileModalVisible] = useState(false);

  const {
    error,
    data,
    refetch,
    variables,
    networkStatus,
    fetchMore
  } = useQuery<GQLJobListings, Variables>(JOB_LISTINGS, {
    pollInterval: 30 * 1000,
    notifyOnNetworkStatusChange: true,
    variables: {
      ...query,
      first: DEFAULT_NUM_LISTINGS
    }
  });
  const shouldFetchMore =
    data?.nonPinnedListings?.pageInfo.hasNextPage ?? false;
  const handleFetchMore = () => {
    fetchMore({
      variables: {
        after: data?.nonPinnedListings?.pageInfo.endCursor
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previous = previousResult as GQLJobListings;
        const result = fetchMoreResult as GQLJobListings;
        const newListings = result.nonPinnedListings?.edges ?? [];
        const pageInfo = result.nonPinnedListings?.pageInfo;

        if (newListings.length === 0) {
          return previousResult;
        }

        return {
          pinnedListings: previous.pinnedListings,
          nonPinnedListings: {
            __typename: previous.nonPinnedListings?.__typename,
            edges: [
              ...(previous.nonPinnedListings?.edges ?? []),
              ...newListings
            ],
            pageInfo
          }
        };
      }
    });
  };
  // const englishNetworkStatus = useMemo(() => {
  //   switch (networkStatus) {
  //     case NetworkStatus.loading:
  //       return "loading";
  //     case NetworkStatus.setVariables:
  //       return "setVariables";
  //     case NetworkStatus.fetchMore:
  //       return "fetchMore";
  //     case NetworkStatus.refetch:
  //       return "refetch";
  //     case NetworkStatus.poll:
  //       return "poll";
  //     case NetworkStatus.ready:
  //       return "ready";
  //     case NetworkStatus.error:
  //       return "error";
  //   }
  // }, [networkStatus]);
  // console.info(englishNetworkStatus);

  const pinnedListings = (data?.pinnedListings?.edges ?? []).flatMap(
    edge => edge?.node ?? []
  );
  const nonPinnedListings = (data?.nonPinnedListings?.edges ?? []).flatMap(
    edge => edge?.node ?? []
  );

  let inner: JSX.Element | null = null;

  switch (networkStatus) {
    case NetworkStatus.error:
      console.error(error);
      inner = (
        <Card variant="error" title="Well, this is embarassing">
          <p>There was an unexpected issue fetching job listings.</p>
          <p className="mt-3">
            This was not your fault and our team has already been notified. In
            the meantime, you can try{" "}
            <button
              className="underline"
              onClick={() => window.location.reload(true)}
            >
              refreshing
            </button>{" "}
            this page.
          </p>
        </Card>
      );
      break;
    case NetworkStatus.loading:
      inner = (
        <div className="flex-grow flex justify-center">
          <Loader
            type="Puff"
            color="#FFCB00"
            height={100}
            width={100}
            visible
          />
        </div>
      );
      break;
    default:
      inner = (
        <>
          {nonPinnedListings.length === 0 ? (
            <div className="flex-grow text-center">
              <p className="text-6xl mb-4">
                <span role="img" aria-label="Sad Face">
                  🤕
                </span>
              </p>
              <p className="text-xl text-center">
                Whoops, we don't currently have any job listings that match your
                criteria.
              </p>
              <p className="text-xl text-center mt-2">
                You can create an{" "}
                <button
                  className="blue-link"
                  onClick={() => window.alert("not implemented")}
                >
                  alert
                </button>{" "}
                to get notified of new listings when available or try adjusting
                your filters.
              </p>
            </div>
          ) : (
            <>
              <ul>
                {pinnedListings.map(listing => (
                  <li key={listing.id}>
                    <JobListing listing={listing} />
                  </li>
                ))}
                {nonPinnedListings.map(listing => (
                  <li key={listing.id}>
                    <JobListing listing={listing} />
                  </li>
                ))}
              </ul>
              {shouldFetchMore && (
                <div className="flex justify-center">
                  <button
                    className="bg-ag-light-purple rounded p-4 text-white"
                    onClick={handleFetchMore}
                  >
                    Fetch older listings
                  </button>
                </div>
              )}
            </>
          )}
        </>
      );
  }

  const isUserInitiatedFetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.fetchMore;

  return (
    <>
      <div className="ag-container mx-auto clearfix">
        <Formik
          initialValues={variables}
          validationSchema={variablesSchema}
          onSubmit={values => {
            setQuery(values);
            refetch(values);
          }}
        >
          {formikProps => (
            <>
              <Form className="initial">
                <FormikAutoSubmit />
                <DesktopFiltersSidebar
                  isFetching={isUserInitiatedFetching}
                  {...formikProps}
                />
              </Form>
              <div
                style={{ maxWidth: "768px", minHeight: "300px" }}
                className="mx-auto px-4 md:ml-300"
              >
                {inner}
              </div>
            </>
          )}
        </Formik>
      </div>
      <div className="md:hidden fixed bottom-0 mb-8 w-full flex justify-center">
        <button
          onClick={() => setMobileModalVisible(true)}
          className="px-4 py-2 text-black bg-ag-yellow rounded uppercase text-sm font-bold"
        >
          Get Notified
        </button>
      </div>
      <Modal
        visible={mobileModalVisible}
        onDismiss={() => setMobileModalVisible(false)}
      >
        <Formik
          initialValues={{ email: "" }}
          validationSchema={alertSchema}
          onSubmit={() => {
            window.alert("not implemented");
            setMobileModalVisible(false);
          }}
        >
          <Form>
            <h1 role="img" aria-label="envelope" className="text-4xl text-center mb-4">
              ✉️
            </h1>
            <p className="text-center leading-loose mb-4">
              We'll send you an alert any time there's a new job posted matching
              your criteria. Unsubscribe anytime!
            </p>
            <Field
              name="email"
              label=""
              type="email"
              placeholder="Your e-mail address"
            />
            <div className="flex flex-row-reverse items-center">
              <button
                type="submit"
                className="px-4 py-2 text-black bg-ag-yellow rounded uppercase text-sm font-bold float-right"
              >
                Submit
              </button>
              <button
                type="button"
                className="mr-4"
                onClick={() => setMobileModalVisible(false)}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default JobListings;
