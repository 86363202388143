import * as yup from "yup";
import * as constants from "../../constants";

const schema = yup.object().shape({
  submitterEmail: yup
    .string()
    .email("Provide a valid e-mail address")
    .required("Required"),
  companyName: yup
    .string()
    .required("Required")
    .trim(),
  title: yup
    .string()
    .required("Required")
    .trim(),
  description: yup.string().required("Required"),
  category: yup
    .string()
    .oneOf(Object.keys(constants.JOB_LISTING_CATEGORIES))
    .required("Select a job category"),
  employmentType: yup
    .string()
    .oneOf(Object.keys(constants.JOB_LISTING_EMPLOYMENT_TYPES))
    .required("Select an employment type"),
  seniority: yup
    .string()
    .oneOf(Object.keys(constants.JOB_LISTING_SENIORITY))
    .required("Select a seniority level"),
  location: yup.string().when("isRemoteAllowed", {
    is: false,
    then: yup.string().required("Required")
  }),
  isRemoteAllowed: yup.boolean().required(),
  salaryMin: yup
    .number()
    .integer("Must be a number")
    .positive("Must be > 0")
    .moreThan(9999, "Must be at least 10,000")
    .lessThan(1000000, "Must be less than 1,000,000")
    .nullable(),
  salaryMax: yup
    .number()
    .integer("Must be a number")
    .positive("Must be > 0")
    .moreThan(yup.ref("salaryMin"), "Must be more than minimum salary")
    .lessThan(1000000, "Must be less than 1,000,000")
    .nullable(),
  apply: yup.string().required("Required")
});

export default schema;