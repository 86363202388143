import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";
import { ApolloProvider } from "@apollo/react-hooks";
import { QueryParamProvider } from "use-query-params";

import "./styles/index.css";
import { useStripe } from "./hooks";
import Home from "./pages/Home";
import JobListingDetail from "./pages/JobListingDetail";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import NewListing from "./pages/NewListing";
import TermsOfUse from "./pages/TermsOfUse";
import { STRIPE_API_KEY } from "./settings";
import { client as apolloClient } from "./apollo";
import NotFound from "./pages/NotFound";

const App: React.FC = () => {
  const stripe = useStripe(STRIPE_API_KEY);

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ApolloProvider client={apolloClient}>
          <StripeProvider stripe={stripe}>
            <div
              style={{ backgroundColor: `rgba(140, 75, 192, 5%)` }}
              className="min-h-screen flex flex-col font-display"
            >
              <NavBar />
              <React.Fragment>
                <Switch>
                  <Route path="/listing/new">
                    <NewListing />
                  </Route>
                  <Route path="/listing/:slug">
                    <JobListingDetail />
                  </Route>
                  <Route path="/terms-of-use">
                    <TermsOfUse />
                  </Route>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              </React.Fragment>
              <Footer />
            </div>
          </StripeProvider>
        </ApolloProvider>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
