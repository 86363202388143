import React, { useMemo } from "react";
import classNames from "classnames";
import { FormikProps } from "formik";
import { animated } from "react-spring/renderprops";
import pick from "lodash/pick";
import isEmpty from "lodash/isEmpty"

import Field from "../../components/Field";
import * as constants from "../../constants";

interface Props extends FormikProps<any> {
  onContinue: () => void;
  style?: React.CSSProperties;
}

const Information: React.FC<Props> = ({
  onContinue,
  values,
  errors,
  isSubmitting,
  setTouched,
  validateForm,
  style
}) => {
  const fields = [
    "submitterEmail",
    "companyName",
    "title",
    "description",
    "location",
    "isRemoteAllowed",
    "category",
    "employmentType",
    "seniority",
    "salaryMin",
    "salaryMax",
    "apply"
  ];
  const handleContinuePressed = async () => {
    const touched = Object.fromEntries(fields.map(field => [field, true]));
    setTouched(touched);
    await validateForm();
  };

  const canSubmit = useMemo(() => {
    const relevantErrors = pick(errors, fields);
    const hasErrors = !isEmpty(relevantErrors);
    return !hasErrors && !isSubmitting;
  }, [errors, isSubmitting])
  return (
    <animated.div style={style}>
      <h1 className="text-3xl text-ag-light-purple font-bold mt-8">
        Company Info
      </h1>
      <hr className="mb-4" />
      <div className="md:flex">
        <div className="md:flex-1">
          <Field
            name="submitterEmail"
            label="Your E-mail"
            helpText="We'll send receipts and a confirmation here"
          />
        </div>
        <div className="md:flex-1 md:ml-2">
          <Field
            name="companyName"
            label="Company Name"
            placeholder="ACME Incorporated"
          />
        </div>
      </div>
      {/* <Field
        name="companyLogo"
        label="Logo"
        type="file"
      /> */}

      <h1 className="text-3xl text-ag-light-purple font-bold mt-8">
        Now, about that position
      </h1>
      <hr className="mb-4" />
      <Field name="title" label="Job Title" placeholder="Coordinator" />
      <Field
        name="description"
        label="Description"
        as="textarea"
        placeholder={`# Responsibilities\n\n- A\n- B\n- C\n\n`}
        helpText={
          <span>
            <a
              href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet"
              target="_blank"
              className="underline text-indigo-500"
              rel="noopener noreferrer"
            >
              Markdown
            </a>{" "}
            is supported and encouraged!
          </span>
        }
        rows={5}
      />

      <Field
        name="location"
        label="Location"
        placeholder="Toronto, ON, Canada"
        disabled={values.isRemoteAllowed}
        className="mb-2"
      />
      <div>
        <Field
          name="isRemoteAllowed"
          label="This is a remote position"
          helpText=""
          type="checkbox"
        />
      </div>

      <div className="md:flex">
        <div className="md:flex-1">
          <Field name="category" label="Category" as="select">
            <option value=""></option>
            {Object.entries(constants.JOB_LISTING_CATEGORIES).map(
              ([short, long]) => (
                <option key={short} value={short}>{long}</option>
              )
            )}
          </Field>
        </div>
        <div className="md:flex-1 md:ml-2">
          <Field name="employmentType" label="Employment Type" as="select">
            <option value=""></option>
            {Object.entries(constants.JOB_LISTING_EMPLOYMENT_TYPES).map(
              ([short, long]) => (
                <option key={short} value={short}>{long}</option>
              )
            )}
          </Field>
        </div>
        <div className="md:flex-1 md:ml-2">
          <Field name="seniority" label="Seniority" as="select">
            <option value=""></option>
            {Object.entries(constants.JOB_LISTING_SENIORITY).map(
              ([short, long]) => (
                <option key={short} value={short}>{long}</option>
              )
            )}
          </Field>
        </div>
      </div>

      <div className="text-gray-700 text-lg font-semibold mb-2">
        Salary Range
      </div>
      <p className="mb-4 text-sm text-gray-600">
        We <strong>strongly</strong> recommend adding this to increase the
        chances of getting applicants.
      </p>

      <div className="flex items-center">
        <div className="flex-1 md:flex-initial mr-1 md:mr-2">
          <Field name="salaryMin" label="" type="number" />
        </div>
        <div>-</div>
        <div className="flex-1 md:flex-initial ml-1 md:ml-2">
          <Field name="salaryMax" label="" type="number" />
        </div>
      </div>

      <Field
        name="apply"
        label="How to apply"
        helpText="Provide a link to an application page or e-mail address"
      />

      <div className="flex justify-end items-center w-full mt-6 mb-12">
        <button
          type="button"
          className={classNames(
            "block text-white font-bold py-2 px-4 rounded",
            {
              "bg-gray-500 hover:bg-gray-700": !canSubmit,
              "bg-ag-light-purple": canSubmit
            }
          )}
          onClick={handleContinuePressed}
          disabled={!canSubmit}
        >
          Continue
        </button>
      </div>
    </animated.div>
  );
};

export default Information;
