import React, { useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface Props {
  onDismiss: () => void;
  visible: boolean;
}

const Modal: React.FC<Props> = ({ visible, onDismiss, children }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onDismiss();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [visible]);

  return (
    <div
      style={{ backgroundColor: "rgba(0, 0, 0, 0.75)" }}
      className={classNames(
        "fixed z-50 top-0 left-0 w-full h-full overflow-auto p-4 pt-12",
        {
          hidden: !visible
        }
      )}
    >
      <div className="absolute top-0 right-0">
        <FontAwesomeIcon
          icon={faTimesCircle}
          size="2x"
          onClick={onDismiss}
          className="mr-2 mt-2 text-white"
        />
      </div>
      <div className="ag-container mx-auto rounded max-h-full bg-gray-100 p-4 overflow-y-scroll">
        {children}
      </div>
    </div>
  );
};

export default Modal;
