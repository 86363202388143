import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";

import termsFile from "./terms.md";


const TermsOfUse = () => {
  const [terms, setTerms] = useState<string | undefined>();
  useEffect(() => {
    fetch(termsFile)
      .then(r => r.text())
      .then(setTerms);
  }, [])
  return (
    <div className="ag-container mx-auto flex-grow p-4 leading-loose">
      <h1 className="text-4xl font-bold text-center my-16">Terms of Use</h1>
      <ReactMarkdown source={terms} />
    </div>
  );
};

export default TermsOfUse;
