import React, { CSSProperties } from "react";

interface Props {
  style?: CSSProperties;
  className?: string;
  width?: number;
  height?: number;
}

const Logo: React.FC<Props> = ({ style, className, width=125, height=89 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 125 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M3.86706 0C1.77676 0 0 1.77675 0 3.86705V77.5501C0 80.0585 1.98579 82.0442 4.49415 82.0442C5.74833 82.0442 6.89799 81.5217 7.73411 80.6855L8.3612 80.163C9.92893 77.9682 15.7818 78.8043 17.1405 83.4029C18.6037 87.8971 22.0527 89.4648 24.8746 86.6429L28.7416 81.7307C30.3094 80.163 32.6087 79.9539 34.49 81.1036L38.357 83.5075C40.3428 84.7616 43.0602 84.3436 44.4189 82.3578L53.0936 70.6521C54.5569 68.6663 57.3788 68.2483 59.4691 69.7115C59.7826 69.9205 59.9916 70.1295 60.2007 70.4431L66.6806 78.0727C68.3528 79.9539 71.1747 80.163 73.056 78.5952C73.1605 78.4907 73.1605 78.3862 73.265 78.3862L84.7617 67.4121C86.5385 65.6354 89.4649 65.7399 91.1371 67.5167C91.4507 67.8302 91.6597 68.1438 91.8687 68.4573L96.3629 76.6095C97.617 78.8043 100.334 79.6404 102.529 78.3862C103.365 77.9682 103.992 77.2366 104.411 76.4004L108.8 67.3076C109.845 65.0083 112.563 64.1722 114.862 65.2173C115.176 65.4264 115.594 65.6354 115.803 65.8444L117.475 67.2031C119.356 68.8754 122.283 68.5618 123.85 66.6805C124.582 65.8444 124.895 64.7993 124.895 63.7541V3.86705C124.895 1.67224 123.119 0 121.028 0H3.86706Z"
        fill="#FFCB00"
      />
      <path
        d="M7.52509 20.3804C7.52509 17.1404 9.92893 15.4682 13.4824 15.4682C14.7366 15.4682 15.9908 15.6772 17.1405 16.0953V15.8863C17.1405 14.3185 16.1998 13.3779 14.1095 13.3779C12.6463 13.3779 11.1831 13.6915 9.82442 14.214L8.77927 10.4515C10.7651 9.61537 12.8554 9.19731 14.9457 9.30182C17.663 9.30182 19.5443 9.92891 20.7985 11.1831C22.0527 12.4373 22.4707 13.9005 22.4707 16.0953V24.8745H17.245V23.3068C16.0953 24.561 14.4231 25.2926 12.7508 25.2926C9.7199 25.0836 7.52509 23.4113 7.52509 20.3804ZM17.245 19.2307V18.4991C16.6179 18.2901 15.8863 18.0811 15.1547 18.0811C13.587 18.0811 12.7508 18.8127 12.7508 19.9623C12.7508 20.903 13.4824 21.6346 14.4231 21.6346C14.5276 21.6346 14.5276 21.6346 14.6321 21.6346C15.9908 21.7391 17.1405 20.6939 17.245 19.3353C17.245 19.3353 17.245 19.3353 17.245 19.2307Z"
        fill="#1D1D1B"
      />
      <path
        d="M23.7249 27.592L25.5017 24.0384C27.0694 24.9791 28.9507 25.5017 30.8319 25.5017C33.4448 25.5017 34.8035 24.143 34.8035 21.7391V21.112C33.6539 22.5752 31.9816 23.4114 30.1003 23.4114C26.4423 23.5159 23.3069 20.5895 23.3069 16.9314C23.3069 16.7224 23.3069 16.5134 23.3069 16.4089V16.3043C23.3069 11.9147 26.4423 9.19732 29.8913 9.19732C31.7726 9.0928 33.5493 9.92892 34.699 11.3921V9.51086H40.0293V21.112C40.0293 23.9339 39.4022 25.9197 38.0435 27.1739C36.6848 28.5326 34.49 29.3687 31.3545 29.3687C28.6371 29.3687 26.0242 28.7416 23.7249 27.592ZM34.8035 16.3043C34.8035 14.6321 33.4448 13.2734 31.7726 13.2734H31.6681C30.1003 13.1689 28.6371 14.4231 28.5326 15.9908C28.5326 16.0953 28.5326 16.0953 28.5326 16.1998C28.5326 17.7675 29.7868 19.1262 31.459 19.1262C31.5636 19.1262 31.5635 19.1262 31.6681 19.1262C33.2358 19.2308 34.699 17.9766 34.8035 16.4089C34.8035 16.4089 34.8035 16.3043 34.8035 16.3043Z"
        fill="#1D1D1B"
      />
      <path
        d="M41.0744 17.1405C40.8654 12.8553 44.2099 9.30183 48.3905 9.0928C48.495 9.0928 48.5995 9.0928 48.8085 9.0928C54.0343 9.0928 56.5426 12.9599 56.5426 17.454C56.5426 17.7675 56.5426 18.1856 56.5426 18.6037H46.3002C46.6137 20.1714 48.0769 21.2165 49.6447 21.112C50.8988 21.112 52.153 20.4849 53.0936 19.5443L56.0201 21.9481C54.4523 24.0384 51.944 25.1881 49.4356 25.0836C44.4189 25.0836 41.0744 21.9481 41.0744 17.1405ZM51.4214 15.8863C51.2124 14.214 50.1672 13.1689 48.704 13.1689C47.2408 13.1689 46.3002 14.214 46.0911 15.8863H51.4214Z"
        fill="#1D1D1B"
      />
      <path
        d="M57.4833 9.51086H62.8136V11.7057C63.8587 10.1379 65.5309 9.30183 67.4122 9.19731C70.6522 9.19731 72.638 11.3921 72.638 14.8411V24.8746H67.3077V16.6179C67.3077 14.9456 66.4716 14.005 65.1129 14.005C63.8587 14.005 62.8136 14.9456 62.8136 16.6179V24.8746H57.4833V9.51086Z"
        fill="#1D1D1B"
      />
      <path
        d="M73.4741 17.1404C73.4741 12.7508 76.9231 9.19731 81.3127 9.0928C81.4172 9.0928 81.5217 9.0928 81.6262 9.0928C84.2391 8.98829 86.7475 10.2425 88.1062 12.4373L84.4482 15.1547C83.8211 14.214 82.7759 13.5869 81.6262 13.5869C79.8495 13.6915 78.4908 15.1547 78.5953 16.9314V17.0359C78.5953 19.0217 79.8495 20.4849 81.6262 20.4849C82.7759 20.4849 83.9256 19.8578 84.5527 18.9172L88.1062 21.5301C86.643 23.8294 84.0301 25.1881 81.3127 25.0836C76.9231 25.0836 73.3696 21.6346 73.3696 17.3495C73.4741 17.245 73.4741 17.245 73.4741 17.1404Z"
        fill="#1D1D1B"
      />
      <path
        d="M98.9758 9.51086H104.515L98.8712 24.4565C97.5126 28.01 96.1539 29.4732 93.0184 29.4732C91.3462 29.4732 89.5694 29.0552 88.1062 28.1145L89.7784 24.352C90.51 24.77 91.2417 25.0836 92.0778 25.1881C92.6003 25.1881 93.0184 24.9791 93.332 24.561L87.3746 9.51086H93.0184L96.0493 18.7082L98.9758 9.51086Z"
        fill="#1D1D1B"
      />
      <path
        d="M7.6296 44.4189C7.6296 40.0292 11.0786 36.4757 15.4682 36.4757C15.5727 36.4757 15.6773 36.4757 15.7818 36.4757C18.3946 36.3712 20.903 37.6254 22.2617 39.8202L18.6037 42.4331C17.9766 41.4924 16.9314 40.8653 15.7818 40.8653C14.005 40.9699 12.6463 42.4331 12.7508 44.2098C12.7508 44.2098 12.7508 44.2098 12.7508 44.3143V44.4189C12.7508 46.4046 14.005 47.8678 15.7818 47.8678C16.9314 47.8678 18.0811 47.2408 18.7082 46.3001L22.2617 48.913C20.7985 51.2123 18.1856 52.571 15.4682 52.4665C11.0786 52.4665 7.52509 49.0175 7.52509 44.7324C7.6296 44.5234 7.6296 44.5234 7.6296 44.4189Z"
        fill="#1D1D1B"
      />
      <path
        d="M22.7843 31.3545H28.2191V38.7751C29.2642 37.3118 30.9365 36.3712 32.8177 36.3712C36.0577 36.3712 38.0435 38.566 38.0435 42.015V52.0484H32.7132V43.7918C32.7132 42.1195 31.8771 41.1789 30.5184 41.1789C29.1597 41.1789 28.2191 42.015 28.2191 43.7918V52.0484H22.8888V31.3545H22.7843Z"
        fill="#1D1D1B"
      />
      <path
        d="M38.6706 44.4189C38.4615 40.2383 41.806 36.5802 45.9866 36.4757C46.0911 36.4757 46.1956 36.4757 46.4047 36.4757C51.6304 36.4757 54.1388 40.3428 54.1388 44.8369C54.1388 45.1505 54.1388 45.5685 54.1388 45.9866H43.8963C44.2099 47.5543 45.5686 48.5995 47.2408 48.4949C48.495 48.4949 49.7492 47.8678 50.5853 46.9272L53.5117 49.3311C51.944 51.4214 49.4356 52.571 46.9273 52.4665C42.1196 52.362 38.6706 49.122 38.6706 44.4189ZM49.1221 43.1647C48.913 41.4924 47.8679 40.4473 46.4047 40.4473C44.9415 40.4473 44.0008 41.4924 43.7918 43.1647H49.1221Z"
        fill="#1D1D1B"
      />
      <path
        d="M54.3478 44.4189C54.3478 39.4021 57.4833 36.3712 61.1413 36.3712C62.9181 36.3712 64.6948 37.1028 65.8445 38.566V31.3545H71.0702V51.9439H65.74V49.9582C64.6948 51.4214 62.9181 52.362 61.1413 52.362C57.4833 52.362 54.3478 49.3311 54.3478 44.4189ZM65.8445 44.4189C66.0535 42.6421 64.7993 41.0744 63.0226 40.8654C61.2458 40.6563 59.6781 41.9105 59.5736 43.6873C59.5736 43.8963 59.5736 44.1053 59.5736 44.4189V44.5234C59.3645 46.3001 60.6187 47.8679 62.3955 47.9724C64.1722 48.1814 65.74 46.9272 65.8445 45.1505C65.8445 44.9414 65.8445 44.7324 65.8445 44.4189Z"
        fill="#1D1D1B"
      />
      <path
        d="M72.0109 44.4189C72.0109 39.4021 75.1463 36.3712 78.8044 36.3712C80.5811 36.3712 82.3579 37.1028 83.5075 38.566V31.3545H88.8378V51.9439H83.5075V49.9582C82.4624 51.4214 80.6856 52.362 78.9089 52.362C75.1463 52.362 72.0109 49.3311 72.0109 44.4189ZM83.5075 44.4189C83.7166 42.6421 82.4624 41.0744 80.6856 40.8654C78.9089 40.6563 77.3412 41.9105 77.2366 43.6873C77.2366 43.8963 77.2366 44.1053 77.2366 44.4189V44.5234C77.0276 46.3001 78.2818 47.8679 80.0585 47.9724C81.8353 48.1814 83.403 46.9272 83.5075 45.1505C83.6121 44.9414 83.6121 44.7324 83.5075 44.4189Z"
        fill="#1D1D1B"
      />
      <path
        d="M89.5694 47.6588C89.5694 44.4189 91.9732 42.7466 95.5268 42.7466C96.7809 42.7466 98.0351 42.9556 99.1848 43.3737V43.1647C99.1848 41.597 98.2441 40.6563 96.1538 40.6563C94.6906 40.6563 93.2274 40.9699 91.8687 41.4924L90.7191 37.7299C92.7049 36.8938 94.7951 36.4757 96.8854 36.5802C99.6028 36.5802 101.484 37.2073 102.738 38.4615C103.992 39.7157 104.411 41.1789 104.411 43.3737V52.153H99.0803V50.5852C97.9306 51.8394 96.2584 52.571 94.5861 52.571C91.7642 52.362 89.5694 50.6898 89.5694 47.6588ZM99.2893 46.5092V45.7776C98.6622 45.5685 97.9306 45.3595 97.199 45.3595C95.6313 45.3595 94.7951 46.0911 94.7951 47.3453C94.7951 48.2859 95.5268 49.0175 96.4674 49.0175C96.5719 49.0175 96.5719 49.0175 96.6764 49.0175C98.0351 49.122 99.1848 48.0769 99.2893 46.7182C99.2893 46.6137 99.2893 46.6137 99.2893 46.5092Z"
        fill="#1D1D1B"
      />
      <path
        d="M105.978 36.7893H111.413V39.8202C112.249 37.7299 113.712 36.3712 116.325 36.4757V42.1195H115.907C113.085 42.1195 111.518 43.6873 111.518 47.2408V52.0484H106.187L105.978 36.7893Z"
        fill="#1D1D1B"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="125" height="88.0016" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
