interface Config {
  docker: any;
  development: any;
  production: any;
  test: any;
}

const settingBasedOnEnv = (config: Config) => {
  const deploymentStage = process.env.REACT_APP_DEPLOYMENT_STAGE ?? "development";
  switch (deploymentStage) {
    case "docker":
      return config.docker;
    case "development":
      return config.development;
    case "production":
      return config.production;
    case "test":
      return config.test;
  }
}

export const SITE_NAME = "Agency Cheddar";

export const SITE_HOST = "www.agencycheddar.com";

export const STRIPE_API_KEY =
  process.env.NODE_ENV === "development"
    ? "pk_test_h5E19KSfA28pjKvdWuwr2T7i00RjlHyAZj"
    : "TBD";

export const API = {
  BASE_URL: settingBasedOnEnv({
    docker: "http://localhost:8080",
    test: "http://localhost:8080",
    development: "https://dev.backend.agencycheddar.com",
    production: "https://backend.agencycheddar.com",
  }),
};


