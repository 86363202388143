import React, { ReactNode } from "react";
import classNames from "classnames";
import { Field as FormikField, ErrorMessage, FieldAttributes } from "formik";

export interface Props extends FieldAttributes<any> {
  label: string | ReactNode | ReactNode[];
  helpText?: string | ReactNode | ReactNode[];
  className?: string;
  labelSize?: "1x" | "2x";
}

const Field: React.FC<Props> = ({
  name,
  label,
  helpText,
  className,
  labelSize = "2x",
  ...rest
}) => {
  className = className ?? "mb-8";

  return (
    <div className={className}>
      {rest.type !== "checkbox" && (
        <label htmlFor={name} className={classNames("block text-gray-700 font-semibold mb-2", {
          "text-lg": labelSize === "2x",
          "text-base": labelSize === "1x",
        })}>
          {label}
        </label>
      )}
      {rest.as === "select" ? (
        <div className="relative">
          <FormikField
            id={name}
            name={name}
            className={classNames({
              "block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-3 py-3 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline":
                rest.type !== "checkbox",
              "cursor-not-allowed bg-gray-300": rest.disabled
            })}
            {...rest}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      ) : (
        <FormikField
          id={name}
          name={name}
          className={classNames({
            "block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-3 py-3 pr-8 rounded leading-tight focus:outline-none focus:shadow-outline":
              rest.type !== "checkbox",
            "mr-2": rest.type === "checkbox",
            "cursor-not-allowed bg-gray-300": rest.disabled
          })}
          {...rest}
        />
      )}
      {rest.type === "checkbox" && (
        <label className="text-gray-700" htmlFor={name}>{label}</label>
      )}
      <ErrorMessage
        name={name}
        component="div"
        className="mt-2 text-sm text-red-600"
      />
      {helpText && <div className="mt-2 text-sm text-gray-600">{helpText}</div>}
    </div>
  );
};

export default Field;
