import React, { useState, useEffect } from "react";
import { FormikProps } from "formik";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Field from "../../components/Field";
import * as constants from "../../constants";

interface Props extends FormikProps<any> {
  isFetching: boolean;
}

const DesktopFiltersSidebar: React.FC<Props> = ({
  isFetching,
  ...formikProps
}) => {
  const [topOffset, setTopOffset] = useState(60 + 40);
  useEffect(() => {
    const node = document.getElementById("navbar");
    if (!node) return;

    setTopOffset(node.getBoundingClientRect().height + 40);
  }, []);

  return (
    <aside
      style={{ top: topOffset }}
      className="hidden md:block w-300 float-left sticky bg-white p-4 border rounded border-gray-300"
    >
      <Field name="keyword" label="Keyword or Title" labelSize="1x" />
      <Field name="location" label="Location" labelSize="1x" placeholder="Toronto, ON, Canada" />
      <Field name="category" label="Category" as="select" labelSize="1x">
        <option value="">All Categories</option>
        {Object.entries(constants.JOB_LISTING_CATEGORIES).map(
          ([short, long]) => (
            <option key={short} value={short.toLowerCase()}>
              {long}
            </option>
          )
        )}
      </Field>
      <Field name="employmentType" label="Employment Type" as="select" labelSize="1x">
        <option value="">All Types</option>
        {Object.entries(constants.JOB_LISTING_EMPLOYMENT_TYPES).map(
          ([short, long]) => (
            <option key={short} value={short.toLowerCase()}>
              {long}
            </option>
          )
        )}
      </Field>
      <Field name="seniority" label="Seniority" as="select" labelSize="1x">
        <option value="">All Levels</option>
        {Object.entries(constants.JOB_LISTING_SENIORITY).map(
          ([short, long]) => (
            <option key={short} value={short.toLowerCase()}>
              {long}
            </option>
          )
        )}
      </Field>
      { isFetching && (
        <div className="absolute top-0 right-0 mr-4 mt-4">
          <Loader
            type="Puff"
            color="#FFCB00"
            height={25}
            width={25}
            visible
          />
        </div>
      )}
    </aside>
  );
};

export default DesktopFiltersSidebar;
