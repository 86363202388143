import { useEffect, useState, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";

export function useStripe(apiKey: string) {
  const [stripe, setStripe] = useState<any>(null);
  useEffect(() => {
    if ((window as any).Stripe) {
      setStripe((window as any).Stripe(apiKey));
    } else {
      document.querySelector("#stripe-js")?.addEventListener("load", () => {
        setStripe((window as any).Stripe(apiKey));
      });
    }
  }, [apiKey]);

  return stripe;
}

interface RectDOM {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export function useMeasure<T extends Element>() {
  const ref = useRef<T | null>(null);
  const [bounds, setBounds] = useState<RectDOM>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  });
  const [resizeObserver] = useState(
    () => new ResizeObserver(([entry]) => setBounds(entry.contentRect))
  );
  useEffect(() => {
    if (!ref.current) return;
    resizeObserver.observe(ref.current);
    return resizeObserver.disconnect;
  }, [ref, resizeObserver]);

  return [ref, bounds] as [React.MutableRefObject<T | null>, RectDOM];
}

export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  type ValFunc = (v: T) => T;

  const setValue = (value: T | ValFunc) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue] as [T, (v: T | ValFunc) => void];
}

export function useTitle(title: string, prefix = "Agency Cheddar - ") {
  useEffect(() => {
    document.title = `${prefix}${title}`;
  }, [title, prefix]);
}
