import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";

import Logo from "./Logo";

const NavBar: React.FC = () => {
  return (
    <header
      id="navbar"
      className="sticky top-0 bg-ag-dark-purple shadow-lg z-10"
    >
      <nav
        style={{ height: 60 }}
        className="ag-container mx-auto flex justify-between items-center p-2"
      >
        <Link to="/" className="px-4 py-2 text-white">
          <Logo style={{ marginTop: 30 }} />
        </Link>
        <Switch>
          <Route
            render={({ location }) =>
              location.pathname.indexOf("/listing/new") === -1 && (
                <Link
                  to="/listing/new"
                  className="px-4 py-2 text-black bg-ag-yellow rounded uppercase text-sm font-bold"
                >
                  Post a Job
                </Link>
              )
            }
          />
        </Switch>
      </nav>
      {/* <Switch>
        <Route path="/" exact>
          <div className="md:hidden bg-ag-light-purple">
            <nav className="ag-container mx-auto flex pt-8 pb-2 px-4 items-center">
              <button className="text-sm bg-white block w-full flex border border-gray-300 rounded p-4 flex focus:outline-none">
                <div className="flex-grow text-left">
                  Account Management &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Toronto, ON, Canada
                </div>
                <FontAwesomeIcon
                  icon={faSlidersH}
                  size="lg"
                  className="ml-4 text-gray-700"
                />
              </button>
            </nav>
          </div>
        </Route>
      </Switch> */}
    </header>
  );
};

export default NavBar;
