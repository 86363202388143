import React from "react";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  return (
    <header
      className="bg-ag-light-purple text-white py-8 mb-16 flex flex-col items-center justify-center"
    >
      <p className="mt-4 text-center text-xl font-light sm:w-3/4 md:w-1/2">
        The #1 destination to find and list incredible agency jobs...
        <span className="w-full" style={{ display: "table" }}>so you can get that cheddar!</span>
      </p>
      <Link
        to="/listing/new/information"
        className="hidden md:inline-block mt-6 bg-ag-yellow font-semibold text-xl px-8 py-4 rounded text-black"
      >
        Post a Job for $299
      </Link>
    </header>
  );
};

export default Header;
